<template>
  <div class="product-parameters">

    <ProductCardModal :product="product" :priceRange="priceRange" :quantity="quantity" :products="upSell" />

    <ProductPricing :product="product" :priceRange="priceRange" :productConfig="productConfig" />

    <Rating :stars="product.rating_summary" :noOfRatings="product.review_count" v-if="product.review_count > 0" />

    <div>
      <span class="gramm" v-html="formateofGramm(product)"></span>
      <span class="vat-text">Preise inkl. MwSt. exkl. zzgl. Versandkosten</span>
    </div>

    <div class="short-desc" v-html="getShortDescription"></div>

    <ProductSelectSimple :parameters="parameters" :product="product" :options="options"
      :productConfig="productConfig" />

    <ProductStoreAvailability :product="product" :productConfig="productConfig" :options="options" />

    <div class="delivery-and-quantity d-flex align-items-baseline">
      <span class="delivery-text"> Lieferzeit ca. 1-3 Tage </span>
      <ProductAddtoCart :product="product" :productPage="true" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductCardModal from "@/esf_kerkrade_vitanatura/core/components/product/ProductCardModal";
import ProductPricing from "@/esf_kerkrade_vitanatura/core/components/product/ProductPricing";
import ProductSelectSimple from "@/base/core/components/product/ProductSelectSimple";
import ProductStoreAvailability from "@/base/core/components/product/ProductStoreAvailability";
import ProductAddtoCart from "@/esf_kerkrade_vitanatura/core/components/product/ProductAddtoCart";
import Rating from "@/esf_kerkrade_vitanatura/core/components/Rating";


export default {
  name: "ProductParameters",
  components: {
  
    ProductAddtoCart,
    ProductStoreAvailability,
    ProductSelectSimple,
    ProductPricing,
    ProductCardModal,
    Rating
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      options: "product/getCurrentProductOptions",
      crossSell: "product/getCrossSellProducts",
      upSell: "product/getUpSell",
    }),

    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    priceRange() {
      if (
        this.product &&
        this.productConfig &&
        this.product.__typename == "ConfigurableProduct"
      ) {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        } else if (this.productConfig.variants.length == 1) {
          return this.productConfig.variants[0].product.price_range;
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    getShortDescription() {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = this.product.short_description.html;

      const listItems = tempDiv.querySelectorAll('li');

      listItems.forEach(li => {
        if (!li.querySelector('span')) {
          const newSpan = document.createElement('span');
          newSpan.innerHTML = li.innerHTML;
          li.innerHTML = '';
          li.appendChild(newSpan);
        }
      });

      const modifiedHtmlString = tempDiv.innerHTML;

      return modifiedHtmlString;
    }
  },

  data: () => ({
    quantity: 1,
  }),
  methods: {
    async copyUrl(url) {
      await navigator.clipboard.writeText(url);
    },
    formateofGramm(data) {
      const final_calcultion = parseFloat(data.price_range.minimum_price.final_price.value) / parseInt(data.verkaufseinheit) * parseInt(data.grundeinheit);
      const text = `${data.verkaufseinheit} Gramm (${(final_calcultion).toFixed(2)} € / ${data.grundeinheit} Gramm)`
      return text
    },
  },
};
</script>
