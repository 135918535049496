<template>
  <div class="d-flex justify-content-between py-10">
    <div v-if="priceRange.minimum_price.final_price.value != priceRange.maximum_price.final_price.value"
      class="top-wrap tw1">

      <div class="d-flex align-items-end price-selection">

        <span v-html="getProductPriceRange.text"></span>

        <span class="discount-comment" v-if="getProductPriceRange.discountType == 1">{{ $t("discount") }}
          {{ priceRange.minimum_price.discount.percent_off.toFixed(0) }}%</span>

        <span class="discount-comment2" v-if="getProductPriceRange.discountType == 2">
          {{ $t("to_discount") }}
          {{ getMaxDiscount }}%
        </span>

      </div>
    </div>
    <div v-if="priceRange.minimum_price.final_price.value == priceRange.maximum_price.final_price.value"
      class="top-wrap tw2">
      <div v-if="priceRange.minimum_price.regular_price.value > priceRange.minimum_price.final_price.value"
        class="d-flex align-items-end price-selection mb-10">
        <span class="normal-price">{{ $t("advice_price") }}:
          <span>{{ formatCurrency(priceRange.minimum_price.regular_price.value) }}</span></span>
      </div>
      <div class="d-flex align-items-end price-selection" v-html="getProductPriceRange.text"></div>
      <div class="d-flex align-items-end price-selection" v-if="priceRange.minimum_price.discount.percent_off > 0">
        <span class="discount-comment">{{ $t("discount") }} {{ priceRange.minimum_price.discount.percent_off.toFixed(0)
          }}%</span>
      </div>
    </div>
    <div v-if="product.brand != null" class="product-logo">
      <b-link :href="`/` + product.brand.urlKey">
        <img :src="product.brand.logo" :alt="product.brand.title" />
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductPricing",
  props: {
    product: Object,
    priceRange: Object,
    productConfig: Object,
    productBundle: Array,
  },
  computed: {
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (v.product.sku == this.$store.getters["product/getCurrentChildSku"]) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (this.product.price_range.minimum_price.final_price.value != this.product.price_range.maximum.final_price.value) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(2) + " - " + this.product.price_range.maximum_price.final_price.value.toFixed(2).replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(2);
      }
      if (this.product.price_range.minimum_price.final_price.value != this.product.price_range.maximum_price.final_price.value) {
        return (
          this.product.price_range.minimum_price.final_price.value.toFixed(2).replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value.toFixed(2).replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(2);
    },
    getProductPriceRange() {

      if (this.product.__typename == "SimpleProduct") {
        return {
          text: '<span class="currentPrice">' + this.formatCurrency(this.finalPrice) + "</span>",
          discountType: 1,
        };
      } else if (this.product.__typename == "BundleProduct") {

        if (this.productBundle) {

          let lowestPrice = this.product.price_range.minimum_price.final_price.value;
          let highestPrice = this.product.price_range.maximum_price.final_price.value;

          if (lowestPrice == highestPrice) {
            return {
              text: '<span class="currentPrice">' + this.formatCurrency(lowestPrice) + "</span>",
              discountType: 1,
            };
          } else {
            return {
              text: '<span class="currentPrice">' + this.formatCurrency(lowestPrice) + " - " + this.formatCurrency(highestPrice) + "</span>",
              discountType: 2,
            };
          }

        } else {
          return 1;
        }
      } else {
        if (this.productConfig) {
          let lowestPrice = this.product.price_range.maximum_price.regular_price.value;
          let highestPrice = this.product.price_range.minimum_price.final_price.value;
          this.productConfig.variants.forEach((v) => {
            if (v.product.price_range.minimum_price.final_price.value < lowestPrice) {
              lowestPrice = v.product.price_range.minimum_price.final_price.value;
            }
            if (v.product.price_range.maximum_price.final_price.value > highestPrice) {
              highestPrice = v.product.price_range.maximum_price.final_price.value;
            }
          });
          if (lowestPrice == highestPrice) {
            return {
              text: '<span class="currentPrice">' + this.formatCurrency(lowestPrice) + "</span>",
              discountType: 1,
            };
          } else {
            return {
              text: '<span class="currentPrice">' + this.formatCurrency(lowestPrice) + " - " + this.formatCurrency(highestPrice) + "</span>",
              discountType: 2,
            };
          }
        } else {
          return 1;
        }
      }
    },
    getMaxDiscount() {
      if (this.priceRange.minimum_price && this.priceRange.maximum_price) {
        return Math.max(
          this.priceRange.minimum_price.discount.percent_off,
          this.priceRange.maximum_price.discount.percent_off
        ).toFixed(0);
      } else if (this.priceRange.minimum_price) {
        return this.priceRange.minimum_price.discount.percent_off.toFixed(0);
      } else {
        return this.priceRange.maximum_price.discount.percent_off.toFixed(0);
      }
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
  },
};
</script>
