<template>
  <div>
    <b-tabs v-if="!isMobile" active-nav-item-class="active-opener" class="product--description-tab">
      <h1 class="product-tab-information pt-20">PRODUKTINFORMATIONEN "{{ product.name }}"</h1>

      <b-tab active title="BESCHREIBUNG">
        <div v-html="product.description.html"></div>
        <div v-for="(info, index) in product.information_attributes" :key="index" class="d-flex mb-4">
          <span class="d-block anwendung" v-if="info.label === 'Anwendung'" v-html="'Anwendung : ' + info.value"></span>
        </div>
      </b-tab>
 
      <!--<b-tab title="Lieferzeit">
        <div v-for="(info, index) in product.information_attributes" :key="index" class="d-flex mb-4">
          <span v-if="info.label == 'Lieferzeit'">
            {{ info.value }}
          </span>
        </div>
      </b-tab> -->

      <b-tab :title="`Bewertungen (${product.review_count})`">
        <ProductReview></ProductReview>
      </b-tab>

      <b-tab :title="`ZUTATEN`">
        <div v-for="(info, index) in product.information_attributes" :key="index" class="d-flex mb-4">
          <span v-if="info.label == 'Zutaten'" v-html="info.value"></span>
        </div>
      </b-tab>

    </b-tabs>

    <div v-if="isMobile" class="description_accordion" role="tablist">

      <div>
        <b-button block v-b-toggle.beschreibung variant="white"
          class="d-flex justify-content-between align-items-center">BESCHREIBUNG
          <font-awesome-icon class="icon-plus" :icon="['fass', 'plus']"/>
          <font-awesome-icon class="icon-minus" :icon="['fass', 'minus']"/>
        </b-button>
        <b-collapse id="beschreibung" accordion="my-accordion">
          <div v-html="product.description.html"></div>
        </b-collapse>
      </div>

      <!-- <div>
        <b-button block v-b-toggle.lieferzeit variant="white"
          class="d-flex justify-content-between align-items-center">LIEFERZEIT
          <font-awesome-icon class="icon-plus" :icon="['fass', 'plus']"/>
          <font-awesome-icon class="icon-minus" :icon="['fass', 'minus']"/>
        </b-button>
        <b-collapse id="lieferzeit" accordion="my-accordion">
          <div v-for="(info, index) in product.information_attributes" :key="index" class="d-flex mb-4">
            <span v-if="info.label == 'Lieferzeit'">
              {{ info.value }}
            </span>
          </div>
        </b-collapse>
      </div> -->

      <div>
        <b-button block v-b-toggle.bewertungen variant="white"
          class="d-flex justify-content-between align-items-center">BEWERTUNGEN
          <font-awesome-icon class="icon-plus" :icon="['fass', 'plus']"/>
          <font-awesome-icon class="icon-minus" :icon="['fass', 'minus']"/>
        </b-button>
        <b-collapse id="bewertungen" accordion="my-accordion">
          <ProductReview></ProductReview>
        </b-collapse>
      </div>

      <div>
        <b-button block v-b-toggle.zutaten variant="white"
          class="d-flex justify-content-between align-items-center">ZUTATEN
          <font-awesome-icon class="icon-plus" :icon="['fass', 'plus']"/>
          <font-awesome-icon class="icon-minus" :icon="['fass', 'minus']"/>
        </b-button>
        <b-collapse id="zutaten" accordion="my-accordion">
          <div v-for="(info, index) in product.information_attributes" :key="index" class="d-flex mb-4">
            <span v-if="info.label == 'Zutaten'" v-html="info.value"></span>
          </div>
        </b-collapse>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductReview from "@/base/core/components/ProductReview";
import {
  isMobile
  // , isTablet, isBrowser
} from "mobile-device-detect";


export default {
  name: "DescriptionTab",
  components: {
    ProductReview
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
  },
  methods: {
    CapitalizeFirst(input) {
      let lower = input.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    },
  },
  data: () => ({ isMobile }),
};
</script>
